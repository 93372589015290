/* TimePickerForm.css */

.custom-form-control {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 8px;
    padding: 10px;
    margin: 8px 0 8px 8px;
    /* Add space above, below, and to the left */
}

.custom-date-picker {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 8px;
    padding: 10px;
    margin: 8px 0 8px 8px;
    /* Add space above and below */
}